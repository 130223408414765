@tailwind base;
@tailwind components;
@tailwind utilities;

.html2canvas-container { height: 1800px !important; }

button::-moz-focus-inner {
    border: 0;
}
button:focus {
    outline: none;
}